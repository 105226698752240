<template>
  <section class="page-content">
    <p class="description">
      W zakładce <strong>Zbieranie aplikacji</strong> wklej link do formularza,
      za pomocą którego będziesz zbierać aplikacje kandydatów na wydarzeniu.
    </p>
    <p class="description">
      Podczas Festiwalu JOBICON będziesz zbierać CV szybko i bezpiecznie. Na
      stoisku Twojej firmy umieścimy kod QR, który po zeskanowaniu będzie
      prowadził do formularza rekrutacyjnego.<br />
      Dla firm biorących udział w Festiwalu nie posiadających własnego systemu
      ATS oferujemy możliwość skorzystania z bezpłatnego testu eRecruiter (<a
        href="https://forms.office.com/pages/responsepage.aspx?id=MHNwraq_OEaisyMUxZ6Iovci2f_gxQdOvDDqJEklwIlUOVhYNkVPRjVFVFFXTklRNzkyRE9TS1BENS4u"
        target="_blank"
        class="text-underline"
      >
        <span>wypełnij formularz, aby skorzystać z testu</span> </a
      >).
    </p>
    <p class="description important-info">
      <a
        href="https://docs.pracuj.pl/marketing/JOBICON/zbieranie_aplikacji_jobicon.pdf"
        download
      >
        <img src="/img/download.svg" class="text-icon" alt="" />
        Pobierz manual „Rekrutacja na JOBICON krok po kroku”
      </a>
    </p>
    <download-data page-type="_er_" v-if="!disabled" />
    <form v-on:submit.prevent="sendData">
      <v-radio-button
        id="erecruiter"
        name="_er_collect"
        label="eRecruiter - dla firm posiadających system lub własny formularz ATS."
        :disabled="disabled"
        @auto-save="sendData(false)"
        v-model="formData._er_collect"
      />
      <v-input
        v-if="formData._er_collect === 'erecruiter'"
        id="_er_erecruiter_link"
        name="_er_erecruiter_link"
        label="Przygotuj dedykowany link do zbierania aplikacji na wydarzeniu"
        type="url"
        placeholder="https://erecruiter.pl"
        :required="true"
        :defaultValue="formData._er_erecruiter_link"
        v-model="formData._er_erecruiter_link"
        @auto-save="sendData(false)"
        :disabled="disabled"
      />
      <v-radio-button
        id="pracuj-zone"
        name="_er_collect"
        label="Rezygnuję ze zbierania spontanicznych aplikacji podczas Jobiconu."
        :disabled="disabled"
        @auto-save="sendData(false)"
        v-model="formData._er_collect"
      />
      <div v-if="userRole === 'administrator' && 0 === 1">
        <h2>Dla administratorów</h2>
        <p class="description">
          Wygeneruj i pobierz plik Word z treściami z tej zakładki
        </p>
        <a
          :href="wordDownloadLink ? wordDownloadLink : null"
          class="btn"
          @click="
            wordDownloadLink
              ? null
              : setWordDownloadLink(keysForWordDownload, formData._er_english)
          "
          download
          >{{ wordDownloadLink ? "Pobierz plik" : "Wygeneruj plik" }}</a
        >
      </div>
      <div v-if="!disabled" class="send-btn-cont">
        <main-btn
          content="Zapisz wersję roboczą"
          tooltip="Wersja robocza umożliwia Ci naniesienie zmian i edytowanie tej zakładki. Pamiętaj aby jak najszybciej zapisać wersję ostateczną materiałów, która zostanie przesłana do organizatora wydarzenia."
          :submit="true"
          :saving="loading"
          :gray="true"
          @click="sendData(false)"
        />
        <main-btn
          :disabled="charactersLeft < 0"
          :content="saveFinalDataText"
          :submit="true"
          tooltip="Wersja ostateczna to finalna informacja jaka zostanie przesłana do Pracuj.pl na potrzeby organizacji wydarzenia"
          :saving="loading"
          @click="
            sendData(true, '_er_disabled', ignoredKeys[formData._er_collect])
          "
        />
      </div>
      <p v-else-if="hasClassicEdition && !serviceWasBought">
        <strong>Ta usługa nie została wykupiona przez Twoją firmę</strong>
      </p>
      <div v-else>
        <p>
          <strong
            >Ustawienia zakładki zostały przekazane do Pracuj.pl. Jeśli chcesz
            dokonać zmian skontaktuj się pod adresem
            <a
              class="important-info"
              :href="`mailto:${companyGuardian.email}`"
              >{{ companyGuardian.email }}</a
            ></strong
          >
        </p>
      </div>
      <p class="text-center margin-tp-1" v-show="message">
        <strong :class="`message--${messageType}`">{{ message }}</strong>
      </p>
    </form>
  </section>
</template>

<script>
import Vue from "vue";
import { mapGetters } from "vuex";
import sendDataAndDispatch from "@/mixins/sendDataAndDispatch.js";
import assignDataFromDatabase from "@/mixins/assignDataFromDatabase.js";
import commonContentLengthForTextarea from "@/mixins/commonContentLengthForTextarea.js";
import wordDownload from "@/mixins/wordDownload.js";
import checkIfServiceIsBought from "@/mixins/checkIfServiceIsBought.js";

export default Vue.extend({
  mixins: [
    sendDataAndDispatch,
    assignDataFromDatabase,
    commonContentLengthForTextarea,
    wordDownload,
    checkIfServiceIsBought,
  ],
  data() {
    return {
      loading: false,
      message: "",
      messageType: "ok",
      disabled: false,
      formData: {
        _er_english: false,
        _er_disabled: false,
        _er_collect: "",
        _er_erecruiter_link: "",
      },
      ignoredKeys: {
        "pracuj-zone": ["_er_erecruiter_link"],
        erecruiter: [],
      },
    };
  },
  computed: {
    ...mapGetters([
      "companyData",
      "companyGuardian",
      "currentCompanyPackage",
      "activeEdition",
      "activeEditionOpened",
      "userRole",
      "activeEditionDetails",
    ]),
    dateTimeNow() {
      const dateNow = new Date().toISOString().slice(0, 10);
      return dateNow;
    },
    keysForWordDownload() {
      return [
        `${this.activeEdition}_er_collect`,
        `${this.activeEdition}_er_erecruiter_link`,
      ];
    },
    hasClassicEdition() {
      return this.currentCompanyPackage.name === "Classic";
    },
    serviceWasBought() {
      return this.checkIfServiceIsBought("Zbieranie aplikacji");
    },
  },
  methods: {
    checkIfPageIsDisabled() {
      this.disabled = this.formData._er_disabled;

      const adsEndDate = new Date(this.activeEditionDetails.adverts_end_date)
        .toISOString()
        .slice(0, 10);

      if (this.dateTimeNow > adsEndDate) {
        this.disabled = true;
      }

      if (this.hasClassicEdition && !this.serviceWasBought) {
        this.disabled = true;
      }
    },
    prepareData() {
      this.assingData("_er_collect", "");
      this.assingData("_er_erecruiter_link", "");
      this.assingData("_er_disabled", false, "boolean");
      this.assingData("_er_english", false, "boolean");
    },
  },
  mounted() {
    this.prepareData();
    this.checkIfPageIsDisabled();
  },
  watch: {
    companyData: function () {
      this.prepareData();
      this.checkIfPageIsDisabled();
    },
    activeEditionOpened() {
      this.checkIfPageIsDisabled();
    },
    activeEditionDetails() {
      this.checkIfPageIsDisabled();
    },
  },
});
</script>
